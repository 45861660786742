.search-box__container {
  width: 80%;
  margin: 2em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label[For="search"] {
  font-size: 1.5rem;
  margin-bottom: 1em;
  font-weight: bold;
}

input[name="search"] {
  border: 1px solid black;
  border-radius: 5px;
  font-size: inherit;
  padding: .5em;
  outline: none;
  width: 100%;
}


.recipe-list {
    border-right: 1px solid black;
    min-height: 100vh;
    width: 50%;
  }
  
.recipe-list__add-recipe-btn-container {
    padding-top: 30px;
    padding-bottom: 30px;
    text-align: center;
  }